import { config, library, dom } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faComputerClassic } from "@fortawesome/pro-regular-svg-icons/faComputerClassic";
import { faFileUpload } from "@fortawesome/pro-regular-svg-icons/faFileUpload";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { faFilePlus } from "@fortawesome/pro-regular-svg-icons/faFilePlus";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faHome } from "@fortawesome/pro-regular-svg-icons/faHome";
import { faGlobeAmericas } from "@fortawesome/pro-regular-svg-icons/faGlobeAmericas";
import { faSlash } from "@fortawesome/pro-regular-svg-icons/faSlash";
import { faListAlt } from "@fortawesome/pro-regular-svg-icons/faListAlt";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import { faLayerGroup } from "@fortawesome/pro-regular-svg-icons/faLayerGroup";
import { faLayerPlus } from "@fortawesome/pro-regular-svg-icons/faLayerPlus";
import { faOctagon } from "@fortawesome/pro-regular-svg-icons/faOctagon";
import { faPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faPlusOctagon } from "@fortawesome/pro-regular-svg-icons/faPlusOctagon";
import { faProjectDiagram } from "@fortawesome/pro-regular-svg-icons/faProjectDiagram";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faShare } from "@fortawesome/pro-regular-svg-icons/faShare";
import { faSignOut } from "@fortawesome/pro-regular-svg-icons/faSignOut";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faUnderline } from "@fortawesome/pro-regular-svg-icons/faUnderline";
import { faBold } from "@fortawesome/pro-regular-svg-icons/faBold";
import { faItalic } from "@fortawesome/pro-regular-svg-icons/faItalic";
import { faStrikethrough } from "@fortawesome/pro-regular-svg-icons/faStrikethrough";
import { faLink } from "@fortawesome/pro-regular-svg-icons/faLink";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faSuperscript } from "@fortawesome/pro-regular-svg-icons/faSuperscript";
import { faSubscript } from "@fortawesome/pro-regular-svg-icons/faSubscript";
import { faCompressAlt } from "@fortawesome/pro-regular-svg-icons/faCompressAlt";
import { faHeading } from "@fortawesome/pro-regular-svg-icons/faHeading";
import { faQuoteRight } from "@fortawesome/pro-regular-svg-icons/faQuoteRight";
import { faCode } from "@fortawesome/pro-regular-svg-icons/faCode";
import { faListUl } from "@fortawesome/pro-regular-svg-icons/faListUl";
import { faListOl } from "@fortawesome/pro-regular-svg-icons/faListOl";
import { faOutdent } from "@fortawesome/pro-regular-svg-icons/faOutdent";
import { faIndent } from "@fortawesome/pro-regular-svg-icons/faIndent";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";
import { faRedo } from "@fortawesome/pro-regular-svg-icons/faRedo";
import { faUndo } from "@fortawesome/pro-regular-svg-icons/faUndo";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";

config.mutateApproach = "sync";

library.add(
  faExclamationTriangle,
  faCheck,
  faFileUpload,
  faComputerClassic,
  faInfoCircle,
  faEye,
  faFile,
  faFilePlus,
  faHome,
  faListAlt,
  faList,
  faLayerGroup,
  faLayerPlus,
  faOctagon,
  faPencil,
  faSlash,
  faPlus,
  faPlusOctagon,
  faProjectDiagram,
  faGlobeAmericas,
  faSearch,
  faShare,
  faSignOut,
  faTrash,
  faUnderline,
  faBold,
  faItalic,
  faStrikethrough,
  faLink,
  faSuperscript,
  faSubscript,
  faCompressAlt,
  faHeading,
  faQuoteRight,
  faCode,
  faTimes,
  faListUl,
  faListOl,
  faOutdent,
  faIndent,
  faPaperclip,
  faRedo,
  faUndo,
  faUser
);

dom.watch();
