import Trix from "trix";

require("@rails/actiontext");

Trix.config.textAttributes.u = { tagName: "u", inheritable: true };
Trix.config.textAttributes.sup = { tagName: "sup", inheritable: true };
Trix.config.textAttributes.sub = { tagName: "sub", inheritable: true };
Trix.config.textAttributes.small = { tagName: "small", inheritable: true };

let lang = Trix.config.lang;

const fullToolbar = (lang) => `
<div class="trix-toolbar flex justify-between mb-2">
  <div class="flex">
    <span class="button-group mr-2" data-trix-button-group="text-tools">
      <button type="button" class="secondary button trix-button--icon trix-button--icon-u" data-trix-attribute="u" data-trix-key="u" tabindex="-1" title="underline"><i class="far fa-underline fa-sm"></i></button>
      <button type="button" class="secondary button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1"><i class="far fa-bold fa-sm"></i></button>
      <button type="button" class="secondary button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1"><i class="far fa-italic fa-sm"></i></button>
      <button type="button" class="secondary button hide-simple trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1"><i class="far fa-strikethrough fa-sm"></i></button>
      <button type="button" class="secondary button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1"><i class="far fa-link fa-sm"></i></button>
      <button type="button" class="secondary button hide-simple trix-button--icon trix-button--icon-sup" data-trix-attribute="sup" tabindex="-1" title="Superscript"><i class="far fa-superscript fa-sm"></i></button>
      <button type="button" class="secondary button hide-simple trix-button--icon trix-button--icon-sub" data-trix-attribute="sub" tabindex="-1" title="Subscript"><i class="far fa-subscript fa-sm"></i></button>
      <button type="button" class="secondary button trix-button--icon trix-button--icon-small" data-trix-attribute="small" tabindex="-1" title="Small"><i class="far fa-compress-alt fa-sm"></i></button>
    </span>
    <span class="button-group mr-2 hide-simple" data-trix-button-group="block-tools">
      <button type="button" class="secondary button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1"><i class="far fa-heading fa-sm"></i></button>
      <button type="button" class="secondary button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1"><i class="far fa-quote-right fa-sm"></i></button>
      <button type="button" class="secondary button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="${lang.code}" tabindex="-1"><i class="far fa-code fa-sm"></i></button>
      <button type="button" class="secondary button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1"><i class="far fa-list-ul fa-sm"></i></button>
      <button type="button" class="secondary button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1"><i class="far fa-list-ol fa-sm"></i></button>
      <button type="button" class="secondary button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1"><i class="far fa-outdent fa-sm"></i></button>
      <button type="button" class="secondary button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1"><i class="far fa-indent fa-sm"></i></button>
    </span>
    <span class="button-group mr-2 hide-simple" data-trix-button-group="file-tools">
      <button type="button" class="secondary button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1"><i class="far fa-paperclip fa-sm"></i></button>
    </span>
  </div>
  <div class="flex hide-simple">
    <span class="button-group" data-trix-button-group="history-tools">
      <button type="button" class="secondary button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1"><i class="far fa-undo fa-sm"></i></button>
      <button type="button" class="secondary button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1"><i class="far fa-redo fa-sm"></i></button>
    </span>
  </div>
</div>
<div class="trix-dialogs" data-trix-dialogs>
  <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
    <div class="trix-dialog__link-fields">
      <input type="url" name="href" class="form-input trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
      <div class="flex ml-2 button-group">
        <input type="button" class="secondary button" value="${lang.link}" data-trix-method="setAttribute">
        <input type="button" class="secondary button" value="${lang.unlink}" data-trix-method="removeAttribute">
      </div>
    </div>
  </div>
</div>
`;

Trix.config.toolbar = {
  getDefaultHTML: function () {
    return fullToolbar(lang);
  },
};
