// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";

import {
  AjaxLoader,
  ContentLoader,
  Form,
  Modal,
  Notification,
} from "@iotrak/base-components";

const application = Application.start();
application.register("ajax-loader", AjaxLoader);
application.register("content-loader", ContentLoader);
application.register("form", Form);
application.register("modal", Modal);
application.register("notification", Notification);
